<template>
    <div class="main">
        <nav-bar />
        <section
            class="hero-section background-img-policy full-screen"
            
        >
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="error-content text-white pt-100 pb-100">
                          
                            <h3 class="text-white pb-5">
                                Política de Privacidade - iLub
                            </h3>
                            <h5>1. OBJETIVO</h5>
                            <p>
                                A CAMP TECNOLOGIA S/A, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 29.244.740/0001-55, com sede na Rua Dr. Pedro Zimmermann, nº 2.464, sala 09, na cidade de Blumenau/SC, CEP 89066-003, doravante denominada Camp Tecnologia, preza pelo tratamento dos pessoais de acordo com a Lei Geral de Proteção de Dados (Lei nº 13.709/2018).
                            </p>
                            <p>
                                A presente Política de Privacidade contém informações a respeito do modo como são tratados, total ou parcialmente, de forma automatizada, os dados pessoais dos usuários que acessam o software “ILub” e os dados pessoais de clientes finais, obtidos na prestação de serviços pela Contratante e registrados neste software.
                            </p>
                            <p>
                                Seu objetivo é repassar as diretrizes de proteção dos dados pessoais coletados e armazenados com base na Lei Geral de Proteção de Dados Pessoais.
                            </p>
                            <h5>2. APLICABILIDADE</h5>
                            <p>
                                A presente Política de Privacidade de Dados Pessoais, se aplica às empresas Contratantes do software, aos usuários e aos clientes finais.
                            </p>

                            <h5>3. RESPONSABILIDADES</h5>

                            <h5>3.1. CONTROLADOR DE DADOS</h5>
                            <ul class="pb-3">
                                <li>Pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais.</li>
                                <li>Define as finalidades para as quais os dados pessoais são processados.</li>
                                <li>Garante a segurança dos dados e toma medidas para proteger as informações.</li>
                                <li>É responsável por decidir por que e como os dados pessoais são processados.</li>
                                <li>Normalmente, o controlador é a empresa ou organização que coleta os dados diretamente do titular dos dados.</li>
                                <li>Tem a responsabilidade final pela conformidade com as leis de proteção de dados e pelos direitos dos titulares dos dados.</li>
                                <li>Informa os titulares dos dados sobre como seus dados estão sendo usados e fornece meios para exercerem seus direitos.</li>
                                <li>Toma medidas em caso de vazamentos de dados ou violações de segurança.</li>
                            </ul>

                            <h5>3.2. OPERADOR DE DADOS</h5>
                            <ul class="pb-3">
                                <li>Pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador.</li>
                                <li>Processa os dados pessoais em nome do controlador, seguindo suas instruções.</li>
                                <li>Implementa medidas de segurança para proteger os dados que estão sob sua responsabilidade.</li>
                                <li>Informa imediatamente o controlador em caso de incidentes de segurança ou violações de dados.</li>
                            </ul>

                            <h5>3.3. ENCARREGADO DE PROTEÇÃO DE DADOS (DPO)</h5>
                            <p>
                                O encarregado é a pessoa responsável pela comunicação entre o titular dos dados pessoais, a empresa e a Autoridade Nacional de Proteção de Dados (ANDP). Dentre as atribuições se tem:
                            </p>
                            <ul class="pb-3">
                                <li>Verificar os riscos existentes.</li>
                                <li>Apontar as medidas corretivas.</li>
                                <li>Avaliar periodicamente a segurança de dados pessoais.</li>
                                <li>Realizar eventuais comunicações com os titulares dos dados ou com o poder público.</li>
                                <li>Coordenar a resposta ao incidente.</li>
                                <li>Realizar a avaliação inicial do impacto.</li>
                                <li>Garantir que todas as etapas do plano de resposta sejam seguidas e documentadas.</li>
                            </ul>

                            <h5>3.4. TITULARES DOS DADOS</h5>
                            <ul class="pb-3">
                                <li>Têm direitos sobre seus dados pessoais, incluindo acesso, correção, exclusão, portabilidade e oposição ao processamento.</li>
                                <li>Devem ser informados sobre como seus dados estão sendo usados e ter meios para exercer seus direitos.</li>
                            </ul>

                            <h5>3.5. AUTORIDADE NACIONAL DE PROTEÇÃO DE DADOS (ANPD)</h5>
                            <ul class="pb-3">
                                <li>Órgão responsável por fiscalizar e garantir o cumprimento da LGPD.</li>
                                <li>Recebe denúncias, investiga violações e aplica sanções em caso de não conformidade com a lei.</li>
                            </ul>

                            <h5>4. REFERÊNCIA LEGAL / NORMATIVA</h5>
                            <p>Lei nº 13.709/2018 – Lei Geral de Proteção de Dados Pessoais.</p>

                            <h5>5. TERMOS E DEFINIÇÕES</h5>
                            <ul class="pb-3">
                                <li><strong>CLIENTE FINAL OU CLIENTE DA CONTRATANTE:</strong> pessoa física atendida pela empresa Contratante do software.</li>
                                <li><strong>CONTRATANTE:</strong> Empresa que adquire o licenciamento de uso do software “ILub”.</li>
                                <li><strong>COOKIES:</strong> São pequenos arquivos de texto criados por um site e armazenados temporariamente no navegador do usuário enquanto este navega na internet.</li>
                                <li><strong>GDPR:</strong> General Data Protection Regulation (GDPR) – Regulamento Geral de Proteção de Dados da União Europeia.</li>
                                <li><strong>ILUB:</strong> Software que utiliza banco de dados fundamentado nos manuais técnicos dos fabricantes automotivos, contendo informações destinadas a auxiliar empresas do setor a oferecer aos consumidores finais produtos compatíveis com a marca e o modelo de cada veículo.</li>
                                <li><strong>LGPD:</strong> Lei Geral de Proteção de Dados Pessoais (L13709/2018).</li>
                                <li><strong>USUÁRIO:</strong> pessoa autorizada, conforme seu nível de permissão, para acessar, cadastrar, utilizar ou registrar informações do cliente final no software.</li>
                                <li><strong>PROFILING:</strong> Direcionador de anúncios e campanhas de marketing.</li>
                            </ul>

                            <h5>6. DIRETRIZES</h5>
                            <p>
                                Esta Política de Privacidade foi elaborada em conformidade com a Lei Federal n. 12.965 de 23 de abril de 2014 
                                (Marco Civil da Internet), com a Lei Federal n. 13.709, de 14 de agosto de 2018 (Lei de Proteção de Dados Pessoais) 
                                e com o Regulamento UE n. 2016/679 de 27 de abril de 2016 (Regulamento Geral Europeu de Proteção de Dados Pessoais - GDPR).
                            </p>

                            <h5>6.1. DIREITOS DOS TITULARES (USUÁRIO E CLIENTE FINAL)</h5>
                            <p>
                                A Camp Tecnologia se compromete a cumprir as normas previstas na LGPD e GDPR, em respeito aos seguintes princípios:
                            </p>
                            <ul class="pb-3">
                                <li>Os dados pessoais serão processados de forma lícita, leal e transparente (licitude, lealdade e transparência).</li>
                                <li>Os dados pessoais serão coletados apenas para finalidades determinadas, explícitas e legítimas, não podendo ser tratados posteriormente de uma forma incompatível com essas finalidades (limitação das finalidades).</li>
                                <li>Os dados pessoais serão coletados de forma adequada, pertinente e limitada às necessidades do objetivo para os quais eles são processados (minimização dos dados).</li>
                                <li>Os dados pessoais serão exatos e atualizados sempre que necessário, de maneira que os dados inexatos sejam apagados ou retificados quando possível (exatidão).</li>
                                <li>Os dados pessoais serão conservados de uma forma que permita a identificação dos titulares dos dados apenas durante o período necessário para as finalidades para as quais são tratados (limitação da conservação).</li>
                                <li>Os dados pessoais serão tratados de forma segura, protegidos do acesso não autorizado ou ilícito e contra a sua perda, destruição ou danificação acidental, adotando as medidas técnicas ou organizativas adequadas (integridade e confidencialidade).</li>
                            </ul>

                            <p>
                                O usuário do aplicativo e cliente final possuem os seguintes direitos como Titulares de Dados, conferidos pela Lei de Proteção de Dados Pessoais LGPD e pelo GDPR:
                            </p>
                            <ul class="pb-3">
                                <li><strong>Direito de confirmação e acesso:</strong> obter a confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for o caso, acessar seus dados pessoais.</li>
                                <li><strong>Direito de retificação:</strong> obter, sem demora injustificada, a retificação dos dados pessoais inexatos que lhe digam respeito.</li>
                                <li><strong>Direito à eliminação dos dados (direito ao esquecimento):</strong> ter seus dados excluídos.</li>
                                <li><strong>Direito à limitação do tratamento dos dados:</strong> limitar o tratamento de seus dados pessoais em determinadas circunstâncias.</li>
                                <li><strong>Direito de oposição:</strong> opor-se ao tratamento de dados pessoais, incluindo o uso para definição de perfil de marketing (profiling).</li>
                                <li><strong>Direito de portabilidade dos dados:</strong> receber os dados pessoais fornecidos em um formato estruturado e de leitura automática.</li>
                                <li><strong>Direito de não ser submetido a decisões automatizadas:</strong> não ficar sujeito a decisões tomadas exclusivamente com base em tratamento automatizado, incluindo definição de perfis (profiling).</li>
                                <li><strong>Direito de solicitar informação sobre a possibilidade de não fornecer consentimento.</strong></li>
                            </ul>

                            <p>
                                O titular dos dados poderá exercer os seus direitos por meio de comunicação escrita e digitalizada, enviada ao Encarregado de Dados (DPO) para o e-mail: <a href="mailto:dpo@camptecnologia.com.br">dpo@camptecnologia.com.br</a>, com o assunto "LGPD-", especificando:
                            </p>
                            <ul class="pb-3">
                                <li>Nome completo, número do CPF (Cadastro de Pessoas Físicas, da Receita Federal do Brasil) e endereço de e-mail.</li>
                                <li>Direito que deseja exercer sobre seus dados pessoais junto a Camp Tecnologia.</li>
                                <li>Data do pedido e assinatura do usuário.</li>
                                <li>Imagem do documento que possa demonstrar ou justificar o exercício de seu direito.</li>
                            </ul>

                            <h5>6.2. DA COLETA DE DADOS E CADASTRO</h5>
  
                            <h5>6.2.1. DADOS DOS USUÁRIOS</h5>
                            <p>
                                A coleta dos dados pessoais dos usuários é realizada para cadastro do primeiro, segundo, terceiro e demais níveis de acesso ao software, de acordo com as permissões. Estes níveis são determinados conforme a forma de contratação entre a Camp Tecnologia e a Contratante.
                            </p>
                            <p>
                                A utilização, pelo usuário, de determinadas funcionalidades do aplicativo dependerá de cadastro, sendo que, nestes casos, os seguintes dados do usuário poderão ser coletados e armazenados: Nome, CPF, telefone, e-mail e foto.
                            </p>
                            
                            <h5>6.2.1.1. DEVER DE NÃO FORNECER DADOS DE TERCEIROS</h5>
                            <p>
                                Durante a utilização do site/aplicativo, a fim de resguardar e de proteger os direitos de terceiros, o usuário deverá fornecer somente seus dados pessoais, sendo proibida a utilização de dados pessoais de terceiros.
                            </p>
                            
                            <h5>6.2.1.2. CONSENTIMENTO DO USUÁRIO</h5>
                            <p>
                                As informações referentes a pessoas físicas somente devem ser coletadas na medida da necessidade para prestação de serviços, e em todas as hipóteses cabíveis o consentimento para o tratamento dos dados deverá ser obtido em conformidade com a legislação vigente.
                            </p>
                            <p>
                                Para utilizar os serviços do aplicativo, o usuário deve ler a presente Política de Privacidade e dar o consentimento, através do aceite no login de acesso. O não consentimento impede o acesso do usuário às funcionalidades do aplicativo. 
                            </p>
                            <p>
                                O usuário tem o direito de retirar seu consentimento a qualquer momento, não comprometendo a licitude do tratamento de seus dados pessoais antes da retirada. A solicitação de retirada do consentimento poderá ser feita para o e-mail: <a href="mailto:dpo@camptecnologia.com.br">dpo@camptecnologia.com.br</a>.
                            </p>
                            
                            <h5>6.2.1.3. Registros de Acessos dos Usuários</h5>
                            <p>
                                Em atendimento às disposições do art. 15, caput e parágrafos, da Lei Federal n. 12.965/2014 (Marco Civil da Internet), os registros de acesso do usuário serão coletados e armazenados por, pelo menos, seis meses.
                            </p>
                            
                            <h5>6.2.2. DADOS DO CLIENTE FINAL</h5>
                            <p>
                                Os dados do Cliente Final serão coletados durante a prestação de serviços da empresa Contratante e registrados no software contratado. Poderão ser solicitados os seguintes dados: Nome, telefone, CPF, endereço, dados do carro (placa, modelo, ano, cor, chassi...).
                            </p>
                            <p>
                                A coleta e utilização se limita aos dados estritamente necessários para a prestação de serviços determinados pela empresa Contratante do Software, de forma leal e transparente.
                            </p>
                            
                            <h5>6.2.2.1. Consentimento do Cliente Final</h5>
                            <ul class="pb-3">
                                <li>O consentimento do Cliente Final é obtido no momento da prestação dos serviços, no ato da coleta dos dados. Este consentimento é explícito e informado, garantindo que o Cliente Final esteja ciente das finalidades específicas para as quais seus dados serão utilizados.</li>
                                <li>O Cliente Final é informado de maneira clara e acessível sobre a necessidade da coleta dos dados e as finalidades de seu uso.</li>
                                <li>O consentimento é registrado no sistema, juntamente com os dados coletados, para fins de conformidade e auditoria.</li>
                                <li>O Cliente Final tem a opção de retirar seu consentimento a qualquer momento, mediante solicitação, e será informado sobre os procedimentos para exercer esse direito.</li>
                                <li>Todos os dados coletados são tratados de acordo com as normas de proteção de dados vigentes, garantindo a privacidade e a segurança das informações do Cliente Final.</li>
                            </ul>

                            <h5>6.3. DO TRATAMENTO DOS DADOS PESSOAIS</h5>
                            <p>
                                O tratamento dos dados pessoais será realizado de acordo com os princípios e diretrizes estabelecidos pela LGPD e GDPR, garantindo que todas as operações com dados sejam efetuadas com a máxima transparência e segurança.
                            </p>
                            <p>
                                Será realizado o tratamento de dados pessoais sem o consentimento do titular dos dados, apenas em razão de interesse legítimo ou para as hipóteses previstas em lei, como:
                            </p>
                            <ul class="pb-3">
                                <li>Para o cumprimento de obrigação legal ou regulatória pelo controlador;</li>
                                <li>Para a realização de estudos por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</li>
                                <li>Para o exercício regular de direitos em processo judicial, administrativo ou arbitral;</li>
                                <li>Para a proteção da vida ou da incolumidade física do titular dos dados ou de terceiro;</li>
                                <li>Para a tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por entidades sanitárias;</li>
                                <li>Quando necessário para atender aos interesses legítimos do controlador ou de terceiro.</li>
                            </ul>

                            <h5>6.3.1.	Controlador</h5>
                            <p>A Camp Tecnologia é o controlador responsável por garantir o tratamento dos dados pessoais coletados:</p>
                            <ul class="pb-3">
                                <li>do usuário - para acesso e utilização do software.</li>
                                <li>do cliente final - pelo armazenamento dos dados pessoais que são coletados durante a prestação de serviços realizada pela empresa Contratante do software.</li>
                            </ul>

                            <h5>6.3.2.	Operador</h5>
                            <p>A Camp Tecnologia designa um ou mais funcionários como operadores responsáveis pelo tratamento dos dados pessoais dos titulares (usuários e clientes finais). Esses operadores realizam mapeamentos detalhados para controlar todo o ciclo de vida dos dados, assegurando conformidade com as regulamentações aplicáveis e a proteção adequada das informações pessoais.</p>

                            <h5>6.3.3.	Encarregado de Dados (DPO)</h5>
                            <p>A Camp Tecnologia nomeou um Encarregado de Dados (DPO), responsável por coordenar todas as atividades relacionadas à proteção de dados pessoais dentro da empresa. O DPO atua como ponto de contato entre a empresa, os titulares dos dados e as autoridades regulatórias, garantindo que as práticas de tratamento de dados estejam em conformidade com as leis e regulamentos aplicáveis. Além disso, o DPO promove a conscientização sobre a importância da proteção de dados entre os funcionários e gerencia as solicitações e reclamações dos titulares dos dados.</p>
                            
                            <h5>6.3.4.	Titular de Dados Pessoais</h5>
                            <p>Os titulares dos dados pessoais são os usuários do software “ILub”, bem como os Clientes finais.</p>
                            <p>Estes, possuem o direito de acessar, corrigir, excluir e portabilizar seus dados pessoais, bem como o direito à transparência sobre o tratamento de seus dados pelo Controlador (Camp Tecnologia).</p>

                            <h5>6.3.5.	Segurança no Tratamento dos Dados Pessoais</h5>
                            <p>A Camp Tecnologia se compromete a aplicar as medidas técnicas, onde os riscos de violações de dados são minimizados com as soluções integradas de proteção e identificação precoce de vulnerabilidades, aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.</p>
                            <p>Para a garantia da segurança, foram adotadas soluções que levem em consideração: as técnicas adequadas; os custos de aplicação; a natureza, o âmbito, o contexto e as finalidades do tratamento; e os riscos para os direitos e liberdades do titular.</p>
                            <p>No entanto, em caso de ataque de hackers ou crackers por culpa exclusiva de terceiros, a Camp Tecnologia se exime de qualquer responsabilidade, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiro.</p>
                            <p>A Camp Tecnologia se compromete, ainda, em comunicar ao titular, em prazo adequado, caso ocorra algum tipo de violação da segurança de seus dados pessoais que possa lhe causar um alto risco para seus direitos e liberdades pessoais.</p>
                            <p>A violação de dados pessoais é uma violação de segurança, que provoque de modo acidental ou ilícito, a destruição, a perda, a alteração, a divulgação ou o acesso não autorizado a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento.</p>
                            <p>Por fim, a empresa se compromete a tratar os dados pessoais do titular com confidencialidade, dentro dos limites legais.</p>

                            <h5>6.4.	PRAZO DE CONSERVAÇÃO DOS DADOS PESSOAIS</h5>
                            <p>Os dados pessoais do titular serão conservados por um período não superior ao exigido para cumprir os objetivos em razão dos quais eles são processados.</p>
                            <p>O período de conservação dos dados é definido de acordo com os seguintes critérios:</p>
                            <ul class="pb-3">
                                <li>
                                    Os dados serão armazenados pelo tempo necessário para a prestação dos serviços.
                                </li>
                            </ul>
                            <p>Os dados pessoais dos usuários apenas poderão ser conservados após o término de seu tratamento nas seguintes hipóteses:</p>
                            <ul class="pb-3">
                                <li>Para o cumprimento de obrigação legal ou regulatória pelo controlador.</li>
                                <li>Para estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais.</li>
                                <li>Para a transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos na legislação.</li>
                                <li>Para uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.</li>
                            </ul>

                            <h5>6.5.	TRANSFERÊNCIA DOS DADOS PESSOAIS</h5>
                            <p>Os dados pessoais dos usuários serão tratados exclusivamente por este aplicativo e não serão compartilhados com terceiros, exceto nas situações em que a Contratante necessitar transferir esses dados para outro software ou plataforma, visando garantir a continuidade e a qualidade na prestação do serviço contratado.</p>
                            <p>Nesses casos, o compartilhamento de dados será restrito ao mínimo necessário para essa finalidade específica e será realizado com a adoção de medidas de segurança técnicas e organizacionais adequadas, conforme os princípios da LGPD. O compartilhamento dos dados é permitido apenas para essa finalidade específica, sendo proibido o compartilhamento com terceiros para outras finalidades.</p>
                            
                            <h5>6.6.	DADOS PESSOAIS SENSÍVEIS</h5>
                            <p>Não serão coletados dados sensíveis do usuário e cliente final, assim entendidos aqueles definidos nos arts. 9º e 10 do GDPR e nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. Assim, dentre outros, não haverá coleta dos seguintes dados:</p>
                            <ul class="pb-3">
                                <li>dados que revelem a origem racial ou étnica, as opiniões políticas, as convicções religiosas ou filosóficas, ou a filiação sindical do usuário;</li>
                                <li>dados genéticos;</li>
                                <li>dados biométricos;</li>
                                <li>dados relativos à saúde do usuário;</li>
                                <li>dados relativos à vida sexual ou à orientação sexual do usuário;</li>
                                <li>dados relacionados a condenações penais ou a infrações ou com medidas de segurança conexas.</li>
                            </ul>	
                            
                            <h5>7.	DADOS DE NAVEGAÇÃO (COOKIES)</h5>
                            <p>Cookies são pequenos arquivos de texto enviados pelo aplicativo ao computador do usuário e que nele ficam armazenados, com informações relacionadas à navegação do aplicativo.</p>
                            <p>Por meio dos cookies, pequenas quantidades de informação são armazenadas pelo navegador do usuário para que nosso servidor possa lê-las posteriormente. Podem ser armazenados, por exemplo, dados sobre o dispositivo utilizado pelo usuário, bem como seu local e horário de acesso ao aplicativo.</p>
                            <p>Os cookies não permitem que qualquer arquivo ou informação sejam extraídos do disco rígido do usuário, não sendo possível, ainda, que, por meio deles, se tenha acesso a informações pessoais que não tenham partido do usuário ou da forma como utiliza os recursos do aplicativo.</p>
                            <p>É importante ressaltar que nem todo cookie contém informações que permitem a identificação do usuário, sendo que determinados tipos de cookies podem ser empregados simplesmente para que o aplicativo seja carregado corretamente ou para que suas funcionalidades funcionem do modo esperado.</p>
                            <p>As informações eventualmente armazenadas em cookies que permitam identificar um usuário são consideradas dados pessoais. Dessa forma, todas as regras previstas nesta Política de Privacidade também lhes são aplicáveis.</p>
                            
                            <h5>7.1.	COOKIES DO APLICATIVO</h5>
                            <p>Os cookies do aplicativo são aqueles enviados ao computador ou dispositivo do usuário e administrador exclusivamente pelo aplicativo.</p>
                            <p>As informações coletadas por meio destes cookies são utilizadas para melhorar e personalizar a experiência do usuário, sendo que alguns cookies podem, por exemplo, ser utilizados para lembrar as preferências e escolhas do usuário, bem como para o oferecimento de conteúdo personalizado.</p>
                            <p>Estes dados de navegação poderão, ainda, ser compartilhados com eventuais parceiros do aplicativo, buscando o aprimoramento dos produtos e serviços ofertados ao usuário.</p>
                            
                            <h5>7.2.	GESTÃO DOS COOKIES E CONFIGURAÇÕES DO NAVEGADOR</h5>
                            <p>O usuário poderá se opor ao registro de cookies pelo aplicativo, bastando que desative esta opção no seu próprio navegador ou aparelho.</p>
                            <p>A desativação dos cookies, no entanto, pode afetar a disponibilidade de algumas ferramentas e funcionalidades do aplicativo, comprometendo seu correto e esperado funcionamento. Outra consequência possível é remoção das preferências do usuário que eventualmente tiverem sido salvas, prejudicando sua experiência.</p>
                            <p>A seguir, são disponibilizados alguns links para as páginas de ajuda e suporte dos navegadores mais utilizados, que poderão ser acessadas pelo usuário interessado em obter mais informações sobre a gestão de cookies em seu navegador:</p>
                            <p>
                                Internet Explorer:<br>
                                <a href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies</a>
                            </p>
                            <p>
                                Safari:<br>
                                <a href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac">https://support.apple.com/pt-br/guide/safari/sfri11471/mac</a>
                            </p>
                            <p>
                                Google Chrome:<br>
                                <a href="https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt">https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt</a>
                            </p>
                            <p>
                                Mozila Firefox:<br>
                                <a href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam">https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-sites-usam</a>
                            </p>
                            <p>
                                Opera:<br>
                                <a href="https://www.opera.com/help/tutorials/security/privacy/">https://www.opera.com/help/tutorials/security/privacy/</a>
                            </p>

                            
                            <h5>8.	GERENCIAMENTO DE INCIDENTES DE SEGURANÇA</h5>
                            <p>
                                Em conformidade com a Lei Geral de Proteção de Dados (LGPD), a Camp Tecnologia possui um Plano de Resposta a Incidentes de Segurança, previsto no documento complementar (ANX-PSI-008 - Gerenciamento de Incidentes), para lidar com qualquer violação de dados pessoais. Este plano é essencial para garantir uma resposta rápida e eficaz em caso de incidentes que possam comprometer a segurança e a integridade dos dados pessoais tratados pela empresa.
                            </p>
                            <p>
                                Todos os incidentes de segurança devem ser imediatamente reportados ao Encarregado de Proteção de Dados (DPO) através do canal de comunicação designado (dpo@camptecnologia.com.br). 
                            </p>

                            <h5>9.	DISPOSIÇÕES GERAIS</h5>
                            <p>
                                Esta Política e Termos de Uso serão regidos e interpretados de acordo com as leis brasileiras.
                            </p>
                            <p>
                                Caso qualquer disposição destes Termos de Uso seja considerada inválida ou inexequível, as demais disposições permanecerão em pleno vigor e efeito.
                            </p>
                            <p>
                                Qualquer falha da Camp Tecnologia em fazer valer qualquer direito ou disposição destes Termos de Uso não constituirá uma renúncia a tal direito ou disposição.
                            </p>
                            <p>
                                A Camp Tecnologia, possui o direito de alterar esta Política a qualquer momento, conforme a finalidade ou necessidade, para adequá-la as disposições de leis ou normas que tenham força jurídica equivalente, cabendo ao Titular verificá-la regularmente ou sempre que for solicitado. Ocorrendo atualizações neste documento e que demandem nova coleta de consentimento, o Titular será notificado por meio dos contatos informados.
                            </p>
                            
                            <h5>10.	DO DIREITO APLICÁVEL E DO FORO</h5>
                            <p>
                                Para a solução das controvérsias decorrentes do presente instrumento, será aplicado integralmente o Direito brasileiro.
                            </p>
                            <p>
                                Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede do editor do aplicativo.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";


export default {
    name: "PoliticasPrivacidade",
    components: {
        NavBar,
    }
    
};
</script>
<style lang="scss" scoped>
h5{
    color: white;
}

ol, ul {
    list-style: unset !important;
    margin-left: 2rem !important;
}
</style>