<template>
    <section id="comunication" class="about-us pt-5">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-6">
                    <div class="about-content-left section-heading d-flex flex-column" style="gap: 2rem">
                        <h2>
                            Use o iLub e melhore sua comunicação com o cliente
                        </h2>

                        <div class="single-feature">
                            <div class="icon-box-wrap d-flex align-items-center flex-column" style="gap: 2rem">
                                <p>
                                    Saiba exatamente quando será a <b>próxima troca</b> de óleo do seu cliente e <b>mantenha um contato ativo</b> e eficiente.
                                </p>
                                <p>
                                    <b>Gerencie o histórico</b> de trocas, <b>envie lembretes</b>  personalizados pro cliente e ofereça um atendimento único.
                                </p>
                                <p>
                                    Esteja <b>mais próximo do cliente</b> e transforme cada troca de óleo em uma experiência de confiança, garantindo fidelização e maior retorno!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="about-content-right">
                        <img
                            src="img/phone-comunication.png"
                            alt="about us"
                            class="img-fluid pl-5"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "About",
};
</script>

<style>
</style>