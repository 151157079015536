<template>
    <section id="automatic" class="feature-section pt-5">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-4">
                    <div class="download-img">
                        <img
                            src="img/impressora.png"
                            alt="download"
                            class="img-fluid"
                        />
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="feature-contents section-heading d-flex flex-column" style="gap: 2rem">
                        <h2>
                            Imprima etiquetas de troca de óleo automaticamente
                        </h2>
                        <p>
                            Com o iLub, você faz os atendimentos na sua troca de óleo <b>sem precisar de papel e caneta.</b> Gere etiquetas completas com todos os dados levantados na troca:
                        </p>
                        <ul>
                            <li>• Itens trocados;</li>
                            <li>• Produtos aplicados;</li>
                            <li>• Quilometragem da próxima troca;</li>
                            <li>• Previsão da próxima troca.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Features",
};
</script>

<style>
</style>