<template>
    <section
        id="contact"
        class="contact-us pt-5 pb-100 gray-light-bg"
    >
        <div class="container">
            <div class="row">
                <div
                    class="col-12 pb-3 message-box d-done"
                    v-bind:class="{
                        'd-none': !isSuccess && !hasError,
                        'd-block': isSuccess || hasError,
                    }"
                >
                    <div
                        class="alert"
                        v-bind:class="{
                            'alert-danger': hasError,
                            'alert-success': isSuccess,
                        }"
                    >
                        {{ alertText }}
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="section-heading">
                        <h3>Comece criando sua conta</h3>
                        <p>
                            Não perca tempo! Crie sua conta agora mesmo preenchendo o formulário 
                            e tenha acesso imediato a todas as funcionalidades do nosso sistema 
                            ainda hoje. É rápido, fácil e o primeiro passo para otimizar sua 
                            rotina e aproveitar todas as vantagens que preparamos para você! 
                        </p>
                    </div>
                    <div class="footer-address">
                        <!-- <h6><strong>Endereço</strong></h6>
                        <p>Blumenau - SC, Rua Dr. Pedro Zimmermmann, 2464, Bairro Itoupavazinha. </p> -->
                        <ul>
                            <!-- <li><span>Phone: +61 2 8376 6284</span></li>
                            <li>
                                <span
                                    >Email :
                                    <a href="mailto:hello@yourdomain.com"
                                        >hello@yourdomain.com</a
                                    ></span
                                >
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-md-7">
                    <form
                        v-on:submit="submit"
                        id="contactForm"
                        class="contact-us-form"
                    >
                        <!-- <h5>Criar conta</h5> -->
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        class="form-control"
                                        name="name"
                                        v-model="name"
                                        placeholder="Nome"
                                        required="required"
                                        maxlength = "100"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <input
                                        type="email"
                                        class="form-control"
                                        name="email"
                                        v-model="email"
                                        placeholder="E-mail"
                                        required="required"
                                        maxlength = "100"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        name="phone"
                                        value=""
                                        class="form-control"
                                        v-model="phone"
                                        @input="updatePhone"
                                        v-mask='"(##) #####-####"'
                                        placeholder="Telefone"
                                        required="required"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <input
                                        type="password"
                                        name="password"
                                        value=""
                                        class="form-control"
                                        v-model="password"
                                        placeholder="Senha"
                                        required="required"
                                        maxlength = "10"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-6 col-12">
                                <div class="form-group">
                                    <input
                                        type="password"
                                        name="confirmPassword"
                                        value=""
                                        class="form-control"
                                        v-model="confirmPassword"
                                        placeholder="Confirmar Senha"
                                        required="required"
                                        maxlength = "10"
                                    />
                                </div>
                            </div>
                            <div v-if="passwordError" class="col-12">
                                <p class="text-danger">As senhas não coincidem.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 mt-3">
                                <button
                                    type="submit"
                                    class="btn solid-btn"
                                    id="btnContactUs"
                                    :disabled="passwordError"
                                >
                                    Cadastrar 
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Contact",
    computed: {
        phone: {
            get() {
                return this.$store.getters.getPhone
            },
            set(value) {
                this.$store.dispatch('updatePhone', value)
            }
        },
        passwordError() {
            return this.password && this.confirmPassword && this.password !== this.confirmPassword;
        },
    },
    props: {
        isGray: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            email: "",
            name: "",
            password: "",
            confirmPassword: "",
            alertText: "",
            hasError: false,
            isSuccess: false,
        };
    },
    methods: {
        updatePhone() {
            // Atualizar o telefone via Vuex
            this.$store.dispatch('updatePhone', this.phone)
        },

        submit: async function (e) {

            try {
                
                e.preventDefault();
    
                if (this.passwordError) {
                    this.alertText = "As senhas devem ser iguais.";
                    this.hasError = true;
                    this.isSuccess = false;
                    return;
                }
    
                let formData = new FormData();
    
                formData.append("nome", this.name);
                formData.append("email", this.email);
                formData.append("telefone",this.phone);
                formData.append("password",this.password);
    
                const response = await fetch(`${window.url}cadastro`, { method: "POST", body: formData })
    
                const { data, success, message } = await response.json()

                if(!data || !success) {
                    
                    this.alertText = message || "Erro no cadastro. Tente novamente.";
                    this.isSuccess = false;
                    this.hasError = true;
                    return
                }
    
                this.name = "";
                this.email = "";
                this.phone = "";
                this.password = "";
                this.confirmPassword = "";
                this.alertText = "Usuário cadastrado com sucesso";
                this.hasError = false;
                this.isSuccess = true;

            } catch (error) {

                this.alertText = error.message || "Ocorreu um erro inesperado.";
                this.isSuccess = false;
                this.hasError = true;
                return
                
            }




            // fetch("https://hml.api.ilub.app/api/v3/cadastro", {
            //     body: formData,
            //     method: "POST",
            // })
            //     .then((response) => {
            //         if (response.status === 200) {
            //             this.name = "";
            //             this.email = "";
            //             this.phone = "";
            //             this.password = "";
            //             this.confirmPassword = "";
            //             this.alertText = "Usuário cadastrado com sucesso";
            //             this.hasError = false;
            //             this.isSuccess = true;
            //         } else {
            //             this.alertText = "Ocorreu um erro, tente novamente.";
            //             this.isSuccess = false;
            //             this.hasError = true;
            //         }
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //         this.alertText = "Ocorreu um erro, tente novamente.";
            //         this.isSuccess = false;
            //         this.hasError = true;
            //     });
        },
        mounted() {
            this.alertText = "";
            this.hasError = false;
            this.isSuccess = false;
        },
    },
};
</script>

<style>
</style>