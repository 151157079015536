<template>
    <section id="promo" class="promo-section pt-100 background-shape-img">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-9">
                    <div class="section-heading text-center mb-5 d-flex flex-column" style="gap: 2rem">
                        <h2>
                            Indicação de itens de manutenção técnica para cada veículo
                        </h2>
                        <p class="lead">
                            O que o iLub entrega na hora da troca de óleo?
                        </p>
                    </div>
                </div>
            </div>
            <div class="row equal">
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg pt-5 pr-5 pl-5 h-75" 
                        style="bottom: -60px;position: relative;"
                    >   
                        <div style="position:relative;top:-120px;">
                            <div class="circle-icon">
                                <img
                                    src="img/generic-sedan.png"
                                    alt="sedan"
                                    style="width:260px;height: 180px;position: relative; margin-bottom: 0px !important;"
                                />
                            </div>
                            <h5 style="margin-bottom: 2rem">+27 mil veículos</h5>
                            <p>
                                Manual técnico de todos os veículos registrados na tabela FIPE.
                            </p>
                            <carousel
                                :items="1"
                                :nav="false"
                                :autoplay="true"
                                :dots="false"
                                :loop="true"
                                :margin="10"
                                slideTransition="linear"
                                :autoplayTimeout="4000"
                                :autoplaySpeed="false"
                                :smartSpeed="4000"
                                :responsive="{
                                    0: { items: 3 },
                                    500: { items: 3 },
                                    600: { items: 3 },
                                    800: { items: 3 },
                                    1200: { items: 3 },
                                }"
                                class="owl-theme clients-carousel dot-indicator owl-loaded owl-drag pr-5 pl-5"
                            >
                                <div class="item single-client-marcas pt-1 pb-1">
                                    <img
                                        src="img/Fiat.png"
                                        alt="Fiat"
                                        class="client-img"
                                    />
                                </div>
                                <div class="item single-client-marcas pt-1 pb-1">
                                    <img
                                        src="img/Chevrolet.png"
                                        alt="Chevrolet"
                                        class="client-img"
                                    />
                                </div>
                                <div class="item single-client-marcas pt-1 pb-1">
                                    <img
                                        src="img/Vw.png"
                                        alt="VW"
                                        class="client-img"
                                    />
                                </div>
                                <div class="item single-client-marcas pt-1 pb-1">
                                    <img
                                        src="img/Ford.png"
                                        alt="Ford"
                                        class="client-img"
                                    />
                                </div>
                                <div class="item single-client-marcas pt-1 pb-1">
                                    <img
                                        src="img/Hyundai.png"
                                        alt="Hyundai"
                                        class="client-img"
                                    />
                                </div>
                                <div class="item single-client-marcas pt-1 pb-1">
                                    <img
                                        src="img/Toyota.png"
                                        alt="Toyota"
                                        class="client-img"
                                    />
                                </div>
                                <div class="item single-client-marcas pt-1 pb-1">
                                    <img
                                        src="img/Honda.png"
                                        alt="Honda"
                                        class="client-img"
                                    />
                                </div>
                                <div class="item single-client-marcas pt-1 pb-1">
                                    <img
                                        src="img/Peugeot.png"
                                        alt="Peugeot"
                                        class="client-img"
                                    />
                                </div>
                                <div class="item single-client-marcas pt-1 pb-1">
                                    <img
                                        src="img/Renault.png"
                                        alt="Renault"
                                        class="client-img"
                                    />
                                </div>
                            </carousel>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg pt-5 pr-5 pl-5 h-75"
                        style="bottom: -60px;position: relative;"

                    >
                        <div style="position:relative;top:-120px;">

                            <div class="circle-icon">
                                <img
                                    src="img/lubrificante-ilub-lateral.png"
                                    alt="oil"
                                    style="width:260px;height: 180px;position: relative; margin-bottom: 0px !important;"
                                />
                            </div>
                            <h5 style="margin-bottom: 2rem">Óleo do motor ideal</h5>
                            <p>
                                Receba a indicação exata do óleo que atende às especificações do motor do veículo.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4">
                    <div
                        class="single-promo single-promo-hover single-promo-1 rounded text-center white-bg pt-5 pr-5 pl-5 h-75"
                        style="bottom: -60px;position: relative;"
                    >
                        <div style="position:relative;top:-120px;">
                            <div class="circle-icon">
                                <img
                                    src="img/generic-filter.png"
                                    alt="filter"
                                    style="width:230px;height: 180px;position: relative; margin-bottom: 0px !important;"
                                />                        
                            </div>
                            <h5 style="margin-bottom: 2rem">Filtros compatíveis</h5>
                            <p>
                                Identifique e realize e conversão rapidamente dos filtros corretos para cada modelo.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>


import carousel from "vue-owl-carousel";
export default {
    name: "Promo",
    components: { carousel },
    props: {
        isGray: {
            type: Boolean,
            default: false,
        },
        paddingTop: {
            type: Boolean,
            default: true,
        },
        paddingBottom: {
            type: Boolean,
            default: true,
        },
    },
};
</script>