<template>
    <section id="placa" class="feature-section">
        <div class="container">
            <div class="row align-items-center justify-content-between" style="margin-top: 2rem !important;">
                <div class="col-md-4">
                    <div class="download-img">
                        <img
                            src="gif/gif-busca-ilub.gif"
                            alt="download"
                            class="img-fluid"
                        />
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="feature-contents section-heading d-flex flex-column" style="gap: 2rem">
                        <h2>
                            O Manual Técnico completo apenas com a Placa do Veículo
                        </h2>
                        <p>
                            Não perca tempo realizando buscas intermináveis em sites duvidosos. 
                            Com o iLub, todas as informações estão na palma da sua mão de forma confiável.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Placa",
};
</script>

<style>
</style>