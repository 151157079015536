<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// window.url = "https://hml.api.ilub.app/api/v3/";
// window.url = "https://api.ilub.app/api/v3/";
window.url = "http://localhost:8000/api/v3/";

window.urlApp = "https://trocadeoleo.ilub.app/";//lembrar de deixar o barra no final
export default {};
</script>

<style lang = "css">
</style>
