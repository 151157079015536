<template>
    <section id="features" class="feature-section gray-light-bg">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-md-4">
                    <div class="download-img">
                        <img
                            src="img/phone-whatsapp.png"
                            alt="download"
                            class="img-fluid"
                        />
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="pt-5 feature-contents section-heading d-flex flex-column" style="gap: 2rem">
                        <h2>
                            Facilite o contato com o cliente através do Whatsapp
                        </h2>
                        <p>
                            Mantenha um registro completo do histórico de serviços e interações, 
                            garantindo um atendimento mais personalizado e fortalecendo o 
                            relacionamento com o seu cliente.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Features",
};
</script>

<style>
</style>