<template>
    <section id="about" class="about-us gray-light-bg">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-md-6">
                    <div class="about-content-left section-heading d-flex flex-column" style="gap: 2rem">

                        <h2>
                            Conversão instantânea de produtos
                        </h2>

                        <p>
                            Troque rapidamente entre marcas de óleos e filtros para facilitar a recomendação. Todas as indicações seguem as recomendações do fabricante.
                        </p>

                        <p>
                            Experimente o app <b>agora mesmo!</b> Aproveite <b>7 dias grátis</b> no iLub e tenha acesso ao banco de dados completo. Clique no botão abaixo e crie sua conta.
                        </p>

                        <a :href="urlApp" class="solid-btn d-flex justify-content-center align-items-center" style="font-weight: bold; color: white; border-radius: 99rem; padding: .5rem 1rem; gap: 1rem; border: none; cursor: pointer; width: fit-content;">
                            
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_6192_7127)">
                                <path d="M19.7615 7.18506H17.3952L16.7375 5.56006C16.0616 3.88936 14.4487 2.81006 12.6278 2.81006H7.60824C5.78776 2.81006 4.17439 3.88936 3.49813 5.56006L2.84045 7.18506H0.47454C0.165857 7.18506 -0.0606156 7.47178 0.0144802 7.76748L0.251625 8.70498C0.304191 8.91357 0.493907 9.06006 0.711685 9.06006H1.50493C0.974124 9.51826 0.632241 10.1843 0.632241 10.9351V12.8101C0.632241 13.4397 0.875709 14.0081 1.26463 14.4479V16.5601C1.26463 17.2503 1.83101 17.8101 2.5294 17.8101H3.79417C4.49256 17.8101 5.05894 17.2503 5.05894 16.5601V15.3101H15.1771V16.5601C15.1771 17.2503 15.7435 17.8101 16.4419 17.8101H17.7066C18.405 17.8101 18.9714 17.2503 18.9714 16.5601V14.4479C19.3603 14.0085 19.6038 13.4401 19.6038 12.8101V10.9351C19.6038 10.1843 19.2619 9.51826 18.7315 9.06006H19.5247C19.7425 9.06006 19.9322 8.91357 19.9848 8.70498L20.2219 7.76748C20.2966 7.47178 20.0702 7.18506 19.7615 7.18506ZM5.84665 6.48857C6.13478 5.77686 6.83238 5.31006 7.60824 5.31006H12.6278C13.4037 5.31006 14.1013 5.77686 14.3894 6.48857L15.1771 8.43506H5.05894L5.84665 6.48857ZM3.79417 12.8022C3.0353 12.8022 2.5294 12.3038 2.5294 11.5562C2.5294 10.8085 3.0353 10.3101 3.79417 10.3101C4.55303 10.3101 5.69132 11.4315 5.69132 12.1792C5.69132 12.9269 4.55303 12.8022 3.79417 12.8022ZM16.4419 12.8022C15.683 12.8022 14.5447 12.9269 14.5447 12.1792C14.5447 11.4315 15.683 10.3101 16.4419 10.3101C17.2007 10.3101 17.7066 10.8085 17.7066 11.5562C17.7066 12.3038 17.2007 12.8022 16.4419 12.8022Z" fill="white"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_6192_7127">
                                <rect width="20.2363" height="20" fill="white" transform="translate(0 0.310059)"/>
                                </clipPath>
                                </defs>
                            </svg>

                            Experimentar Grátis!
                        </a>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="about-content-right">
                        <img
                            src="img/conversao-lubrificantes.png"
                            alt="about us"
                            class="img-fluid pl-5"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>


export default {
    name: "About",
    data: function () {
        return {
            urlApp: `${window.urlApp}`,
        };
    },
};
</script>

<style>
</style>