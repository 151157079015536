<template>
    <div class="main">
        <nav-bar />
        <section
            class="hero-section background-img-policy full-screen"
            
        >
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <div class="pt-100 pb-100 error-content text-white">
                          
                            <h3 class="text-white pb-5">
                                Termos de Uso - iLub
                            </h3>   
                            <h5>8.	TERMO DE USO DO SOFTWARE</h5>
                            <h5>8.1.	ACEITAÇÃO DOS TERMOS</h5>
                            <p>Ao acessar ou utilizar o software "ILub" fornecido pela Camp Tecnologia, o usuário concorda em cumprir e ficar vinculado aos presentes Termos de Uso e todas as políticas e diretrizes incorporadas por referência.</p>
                            
                            <h5>8.2.	LICENÇA DE USO</h5>
                            <p>A Camp Tecnologia concede ao usuário uma licença limitada, não exclusiva, não transferível e revogável para acessar e utilizar o software conforme os termos e condições estabelecidos neste documento.</p>
                        
                            <h5>8.3.	ACESSO E USO DO SOFTWARE</h5>
                            <p>O acesso aos softwares é permitido somente para os usuários devidamente cadastrados e autorizados pela Contratante.</p>
                            <p>O usuário deve utilizar o software de maneira responsável, ética e em conformidade com todas as leis e regulamentos aplicáveis.</p>
                            <p>É proibido ao usuário:</p>
                            <ul class="pb-3">
                                <li>Compartilhar seu login e senha com terceiros.</li>
                                <li>Utilizar o software para qualquer fim ilegal ou não autorizado.</li>
                                <li>Tentar interferir ou comprometer a integridade ou segurança do software.</li>
                                <li>Reproduzir, duplicar, copiar, vender, revender ou explorar qualquer parte do software sem a expressa permissão por escrito da Camp Tecnologia.</li> 
                            </ul>	

                            <h5>8.4.	OBRIGAÇÕES DO USUÁRIO</h5>
                            <p>Fornecer informações precisas e atualizadas durante o processo de cadastro.</p>
                            <p>Manter a confidencialidade de suas credenciais de acesso.</p>
                            <p>Notifica r imediatamente a Camp Tecnologia sobre qualquer uso não autorizado de sua conta ou qualquer outra violação de segurança.</p>

                            <h5>8.5.	DAS ALTERAÇÕES DO SOFTWARE</h5>
                            <p>A Camp Tecnologia se reserva no direito de modificar, a qualquer momento e sem qualquer aviso prévio, o software citado nesta Política, especialmente para adaptá-las às evoluções e melhorias, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.</p>
                            <p>Dessa forma, convida-se o usuário a consultar periodicamente o software que possui acesso, para verificar as atualizações.</p>
                            <p>Ao utilizar o serviço após eventuais modificações, o usuário demonstra sua concordância com as novas normas. Caso discorde de alguma das modificações, deverá apresentar a sua ressalva ao serviço de atendimento, se assim o desejar.</p>
                            <p>O Termo de Uso pode ser alterado pela Camp Tecnologia a qualquer momento. O uso continuado do software após a publicação de tais alterações constitui a aceitação dos novos termos.</p>

                            <h5>8.6.	LIMITAÇÃO DE RESPONSABILIDADE</h5>
                            <p>A Camp Tecnologia não será responsável por quaisquer danos diretos, indiretos, incidentais, especiais, consequenciais ou punitivos resultantes de:</p>
                            <ul class="pb-3">
                                <li>Uso ou incapacidade de uso do software.</li>
                                <li>Acesso não autorizado ou alteração de suas transmissões ou dados.</li>
                                <li>Qualquer outra questão relacionada ao software.</li>
                            </ul>	

                            <h5>8.7.	RESCISÃO</h5>
                            <p>A Camp Tecnologia pode rescindir ou suspender o acesso do usuário ao software imediatamente, sem aviso prévio, por qualquer motivo, incluindo, mas não se limitando a violação dos Termos de Uso.</p>
                            <p>Em caso de rescisão, o usuário deve cessar imediatamente o uso do software e excluir todas as cópias instaladas em sua posse.</p>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";


export default {
    name: "TermosUso",
    components: {
        NavBar,
    }
};
</script>
<style lang="scss" scoped>
h5{
    color: white;
}

ol, ul {
    list-style: unset !important;
    margin-left: 2rem !important;
}
</style>