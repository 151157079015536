<template>
    <div>
        <nav-bar />
        <div class="main">
            <banner />
            <placa />
            <!-- <companies /> -->
            <promo />
            <about />
            <distributor />
            <features />
            <download />
            <automatic />
            <comunication />
            <fluxo />
            <pricing  />
            <!-- <contact :is-gray="true" /> -->
            <!-- <screenshots /> -->
            <site-footer />
        </div>
    </div>
</template>

<script>
import NavBar from "../../views/commons/NavBar";
import Banner from "../../views/index-one/Banner";
// import Companies from "../../views/commons/Companies";
import Placa from "../../views/index-one/Placa";
import Promo from "../../views/index-one/Promo";
import About from "../../views/index-one/About";
import Features from "../../views/index-one/Features";
import Download from "../../views/index-one/Download";
import Automatic from "../../views/index-one/Automatic";
import Comunication from "../../views/index-one/Comunication";
import Distributor from "../../views/commons/Distributor";
import Fluxo from "../../views/index-one/Fluxo";
import Pricing from "../../views/index-one/Pricing";
// import Contact from "../../views/commons/Contact";
import SiteFooter from "../../views/commons/SiteFooter";
// import Screenshots from "../../views/commons/Screenshots";

export default {
    name: "IndexOne",
    components: {
        NavBar,
        Banner,
        // Companies,
        Placa,
        Promo,
        About,
        Features,
        Download,
        Automatic,
        Comunication,
        Distributor,
        Fluxo,
        Pricing,
        // Contact,
        SiteFooter,
        // Screenshots,
    },
};
</script>