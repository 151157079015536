<template>
    <footer class="footer-section">
        <!-- <div class="shape-img subscribe-wrap">
            <img
                src="img/footer-top-shape.png"
                alt="footer shape"
                class="img-fluid"
            />
        </div> -->
        <div class="bg-footer-custom footer-top pt-5 pb-5 background-img-2">
            <div class="container">
                <div class="flex-direction px-3 align-items-center">

                    <img src="img/logo.svg" width="70" alt="logo" class="img-fluid"/>

                    <div class="footer-nav-wrap text-white margin ml-auto">
                        <ul class="social-list list-inline list-unstyled">
                            <li style="text-align: left;"><a href="/politica/uso" target="_blank"><span style="vertical-align: middle;font-size:1.8rem;color:#CE1D42;">•</span> Termos de Uso</a></li>
                            <li style="text-align: left;"><a href="/politica/privacidade" target="_blank"><span style="vertical-align: middle;font-size:1.8rem;color:#CE1D42;">•</span> Política de privacidade </a></li>
                        </ul>
                    </div>

                    <div class="d-flex margin" style="margin-left: 5rem; gap: 1rem">
                        <a><svg style="fill: white; height: 1.2rem; cursor: pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a>
                        <a><svg style="fill: white; height: 1.2rem; cursor: pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"/></svg></a>
                    </div>

                </div>
                <hr style="background-color: rgb(255 255 255 / 56%); margin: 1.8rem 0rem">
                <div class="row justify-content-between">
                    <div class="col-lg-6 ml-auto mb-3 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <li class="list-inline-item">
                                Feito com

                                <svg style="fill: white; height: .9rem; margin: 0px 3px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8l0-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5l0 3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20-.1-.1s0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5l0 3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2l0-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"/></svg>
                                
                                em Contagem/MG para todo Brasil. © 2025<br>
                                Camp Tecnologia - CNPJ: 29.244.740/0001-55
                            </li>
                        </div>
                    </div>
                    <div class="col-lg-6 ml-auto mb-3 mb-lg-0">
                        <div class="footer-nav-wrap text-white">
                            <ul class="list-unstyled">
                                <li>
                                    <img
                                        src="img/compra-segura.svg"
                                        width="400"
                                        alt="Compra segura"
                                        class="img-fluid"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "SiteFooter",
};
</script>

<style scoped lang="scss">
.bg-footer-custom {
    background: linear-gradient(to right, #000033, #02021D) !important;
}

.flex-direction {
    display: flex;
}

@media (max-width: 460px) {
    .flex-direction {
        flex-direction: column;
        align-items: start !important;
        gap: 1rem
    }
    .margin {
        margin: 0px !important;
    }
}
</style>