<template>
    <transition name="modal">
        <div class="modal-mask" style="z-index:4 !important;">
            <div class="modal-wrapper">

                <div class="bg-white d-flex flex-column" style="height: fit-content; margin: 1rem; padding: 2rem; gap: 2rem; border-radius: 2rem">
                    
                    <svg @click="close" style="margin-left: auto; height: 1.2rem; fill: #464E5F" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M11.0327 8L15.5814 3.45136C16.1395 2.89318 16.1395 1.98818 15.5814 1.42955L14.5705 0.418636C14.0123 -0.139545 13.1073 -0.139545 12.5486 0.418636L8 4.96727L3.45136 0.418636C2.89318 -0.139545 1.98818 -0.139545 1.42955 0.418636L0.418636 1.42955C-0.139545 1.98773 -0.139545 2.89273 0.418636 3.45136L4.96727 8L0.418636 12.5486C-0.139545 13.1068 -0.139545 14.0118 0.418636 14.5705L1.42955 15.5814C1.98773 16.1395 2.89318 16.1395 3.45136 15.5814L8 11.0327L12.5486 15.5814C13.1068 16.1395 14.0123 16.1395 14.5705 15.5814L15.5814 14.5705C16.1395 14.0123 16.1395 13.1073 15.5814 12.5486L11.0327 8Z"/></svg>

                    <div class="d-flex flex-direction" style="gap: 2rem; width: 100%">


                        <section class="d-flex flex-column" style="gap: 2rem; width: 100%">
    
                            <h3>Inicie no iLub grátis!</h3>
                            <p>Realize o cadastro preenchendo os campos ao lado e acesse agora mesmo todos os benefícios do iLub.</p>
    
                            <span>Você receberá um e-mail de confirmação no endereço cadastrado.</span>
    
                        </section>
    
                        <section v-if="!created" class="d-flex flex-column" style="gap: 1rem; width: 100%">
    
                            <input type="text" placeholder="Nome" v-model="user.nome" style="padding: .5rem 1rem; border: 2px solid rgb(137 146 163); border-radius: .5rem;"/>
                            
                            <input type="email" placeholder="E-mail" v-model="user.email" style="padding: .5rem 1rem; border: 2px solid rgb(137 146 163); border-radius: .5rem;"/>
                            
                            <input type="text" placeholder="Telefone" v-model="user.telefone" v-mask='"(##) #####-####"' style="padding: .5rem 1rem; border: 2px solid rgb(137 146 163); border-radius: .5rem;"/>
                            
                            <div class="d-flex" style="width: 100%; gap: 1rem">
                                <input type="password" placeholder="Senha" v-model="user.password" style="width: 100%; padding: .5rem 1rem; border: 2px solid rgb(137 146 163); border-radius: .5rem;"/>
                                <input type="password" placeholder="Confirmar senha" v-model="user.confirmPassword" style="width: 100%; padding: .5rem 1rem; border: 2px solid rgb(137 146 163); border-radius: .5rem;"/>
                            </div>
                            
                            <span v-if="message" style="display: flex; justify-content: start; align-items: center; gap: .5rem; font-size: .8rem; color: #FF2B57; background: #ff000030; border-radius: .3rem; padding: .4rem 1rem;">
                                <svg style="fill: #FF2B57; height: 1.2rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24l0 112c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-112c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
                                {{ message }}
                            </span>

                            <span v-if="successMessage" style="display: flex; justify-content: start; align-items: center; gap: .5rem; font-size: .8rem; color: rgb(44 185 95); background: rgb(134 239 172 / 47%); border-radius: .3rem; padding: .4rem 1rem;">
                                <svg style="fill: rgb(44 185 95); height: 1.2rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
                                {{ successMessage }}
                            </span>

                            <button @click="singin" class="solid-btn d-flex justify-content-center align-items-center" style="font-weight: bold; border-radius: .5rem; padding: .5rem; gap: 1rem; border: none">
                                {{ loading ? 'Cadastrando...' : 'Cadastrar' }}
                                <svg v-if="loading" style="height: 1.4rem; fill: white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/><path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"><animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite"/></path></svg>
                            </button>
    
                        </section>

                        <section v-else class="d-flex flex-column justify-content-center align-items-center" style="gap: 1rem; width: 100%">

                            <svg style="height: 3rem; margin-bottom: 2rem" viewBox="0 0 150 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M131.086 17.171C125.414 17.171 123.407 18.4593 122.038 19.0632V3.35542C122.038 2.38667 121.25 1.60229 120.281 1.60229H111.54C110.568 1.60229 109.783 2.38667 109.783 3.35542V59.9429C111.175 60.3617 114.529 62.4078 127.468 62.4078C140.407 62.4078 150.001 54.6054 150.001 39.7093C150.001 24.8132 141.734 17.1718 131.086 17.1718L131.086 17.171ZM133.993 52.464C132.404 53.5468 130.663 54.1093 128.747 54.0382C126.118 53.939 123.946 52.8648 122.257 50.8421C120.932 49.2523 120.302 47.3968 120.27 45.3507C120.25 44.2007 120.536 43.0867 120.932 42.0039C121.507 40.4453 122.384 39.0578 123.244 37.6578C124.818 35.0882 126.361 32.4992 127.677 29.7875C128.193 28.721 128.708 27.6539 128.95 26.4804C128.957 26.4406 128.986 26.4093 129.013 26.3578C129.382 27.8765 130.064 29.2445 130.805 30.5726C132.28 33.2015 133.811 35.7984 135.318 38.4078C136.245 40.014 137.074 41.6554 137.507 43.4789C138.288 46.7898 136.813 50.5484 133.993 52.4632V52.464Z" fill="#02021D"/>
                                <path d="M63.191 61.079C61.1316 58.5102 59.6566 55.4493 58.8098 51.9883C58.677 51.4454 58.5699 50.8813 58.4605 50.3188H36.9707V3.35708C36.9707 2.38755 36.1848 1.60083 35.2145 1.60083H25.8949C24.9254 1.60083 24.1387 2.38677 24.1387 3.35708V59.5844C24.1387 60.554 24.9246 61.3407 25.8949 61.3407H63.4301C63.3543 61.2501 63.2637 61.1719 63.1902 61.079H63.191Z" fill="#02021D"/>
                                <path d="M102.07 59.8594C99.9855 60.4641 97.2996 61.0258 94.009 61.5461C90.7191 62.0665 87.2645 62.3274 83.6457 62.3274C80.027 62.3274 76.9145 61.8344 74.4746 60.8469C72.0348 59.8602 70.102 58.4758 68.677 56.6938C67.2504 54.9125 66.2355 52.7875 65.6332 50.3196C65.0293 47.8516 64.7285 45.1383 64.7285 42.1766V19.8336C64.7285 18.8641 65.5145 18.0774 66.4848 18.0774H75.2277C76.1973 18.0774 76.984 18.8633 76.984 19.8336V40.6961C76.984 44.6438 77.5043 47.4954 78.5473 49.25C79.5879 51.0055 81.5355 51.8821 84.3871 51.8821C85.2637 51.8821 86.1965 51.8407 87.1832 51.7586C88.1699 51.6766 89.0465 51.5813 89.8152 51.4704V19.8336C89.8152 18.8641 90.6012 18.0774 91.5715 18.0774H100.314C101.284 18.0774 102.071 18.8633 102.071 19.8336V59.8602L102.07 59.8594Z" fill="#02021D"/>
                                <path d="M11.6924 61.3399H2.94863C1.9791 61.3399 1.19238 60.554 1.19238 59.5836V26.9508C1.19238 25.9813 1.97832 25.1946 2.94863 25.1946H11.6916C12.6611 25.1946 13.4479 25.9805 13.4479 26.9508V59.5836C13.4479 60.5532 12.6619 61.3399 11.6916 61.3399H11.6924Z" fill="#FF2B57"/>
                                <path d="M14.6406 7.94214C14.6406 10.1914 13.9125 11.9601 12.4609 13.2468C11.0078 14.5367 9.29453 15.1804 7.31953 15.1804C5.34453 15.1804 3.63203 14.5367 2.17891 13.2468C0.726562 11.9601 0 10.1906 0 7.94214C0 5.6937 0.726562 3.92573 2.17969 2.63745C3.63203 1.34917 5.34609 0.703857 7.31953 0.703857C9.29297 0.703857 11.007 1.34839 12.4609 2.63667C13.9125 3.92573 14.6406 5.69448 14.6406 7.94136V7.94214Z" fill="#FF2B57"/>
                            </svg>

                            <h3>Muito Obridado!</h3>
                            <p>Agora você pode agilizar suas trocas de óleo com apenas um toque.</p>

                            <a :href="user?.email ? `${urlApp}login?email=${user?.email}` : urlApp" class="solid-btn d-flex justify-content-center align-items-center" style="font-weight: bold; color: white; border-radius: .5rem; padding: .5rem; gap: 1rem; border: none; width: 100%; cursor: pointer">
                                Ir para o app
                                <svg style="height: 1.4rem; fill: white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
                            </a>

                        </section>
                    </div>
                </div>









                <!-- <div class="modal-container" style="width: 410px !important;height: 550px !important;">
                    <div class="row p-4">
                        <div class="col-sm-10">
                            <p class="mb-0" style="font-size: 16px;font-family: 'Open Sans';font-weight:600;">Cadastro</p>
                            <p class="mb-0" style="font-size: 16px;font-family: 'Open Sans';font-weight:400;">Preencha os campos abaixo:</p>
                        </div>
                        <div class="col-sm-2">
                            <button class="close-button" @click="close">x</button>
                        </div>
                    </div>
                    <div class="row">
                        <div
                            class="col-12 pb-3 message-box d-done"
                            v-bind:class="{
                                'd-none': !isSuccess && !hasError,
                                'd-block': isSuccess || hasError,
                            }"
                        >
                            <div
                                class="alert"
                                v-bind:class="{
                                    'alert-danger': hasError,
                                    'alert-success': isSuccess,
                                }"
                            >
                                {{ alertText }}
                            </div>
                        </div>
                    </div>
                    <div class="row pb-4 pl-4 pr-4">
                        <div class="col-md-12">
                            <form
                                v-on:submit="submit"
                                id="contactForm"
                                class="contact-us-form"
                            >
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input
                                                type="text"
                                                class="form-control"
                                                name="name"
                                                v-model="name"
                                                placeholder="Nome Completo"
                                                required="required"
                                                maxlength = "100"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input
                                                type="email"
                                                class="form-control"
                                                name="email"
                                                v-model="email"
                                                placeholder="E-mail"
                                                required="required"
                                                maxlength = "100"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input
                                                type="text"
                                                name="phone"
                                                value=""
                                                class="form-control"
                                                v-model="phone"
                                                @input="updatePhone"
                                                v-mask='"(##) #####-####"'
                                                placeholder="Telefone"
                                                required="required"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input
                                                type="password"
                                                name="password"
                                                value=""
                                                class="form-control"
                                                v-model="password"
                                                placeholder="Senha"
                                                required="required"
                                                maxlength = "10"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input
                                                type="password"
                                                name="confirmPassword"
                                                value=""
                                                class="form-control"
                                                v-model="confirmPassword"
                                                placeholder="Confirmar Senha"
                                                required="required"
                                                maxlength = "10"
                                            />
                                        </div>
                                    </div>
                                    <div v-if="passwordError" class="col-12">
                                        <p class="text-danger">As senhas não coincidem.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <button
                                            type="submit"
                                            class="btn solid-btn"
                                            id="btnContactUs"
                                            :disabled="passwordError"
                                        >
                                            Cadastrar 
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ModalBase',
    computed: {
        phone: {
            get() {
                return this.$store.getters.getPhone
            },
            set(value) {
                this.$store.dispatch('updatePhone', value)
            }
        },
        passwordError() {
            return this.password && this.confirmPassword && this.password !== this.confirmPassword;
        },
    },
    props: {
        isGray: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {

            loading: false,
            user: { nome: '', email: '', telefone: '', password: '', confirmPassword: '' },
            message: '',
            successMessage: '',
            created: false,
            urlApp: `${window.urlApp}`,


            email: "",
            name: "",
            password: "",
            confirmPassword: "",
            alertText: "",
            hasError: false,
            isSuccess: false,
        };
    },
    methods: {
        close() {
            this.$emit('close');
        },
        updatePhone() {
            // Atualizar o telefone via Vuex
            this.$store.dispatch('updatePhone', this.phone)
            this.$store.dispatch('updatePhone', this.user.telefone)
        },
        async singin() {

            try {

                if(this.loading) return

                this.created = false

                this.message = ''

                this.successMessage = ''

                const { nome, email, telefone, password, confirmPassword } = this.user

                console.log(this.user)

                if(!nome || !email || !telefone || !password || !confirmPassword) return this.message = 'Preencha todos os campos!'

                if(password !== confirmPassword) return this.message = 'Senhas não coincidem!'

                this.loading = true;

                let formData = new FormData();
    
                formData.append("nome", this.user.nome);
                formData.append("email", this.user.email);
                formData.append("telefone",this.user.telefone);
                formData.append("password",this.user.password);
    
                const response = await fetch(`${window.url}cadastro`, { method: "POST", body: formData })

                const { data, success, message } = await response.json()

                if(!data || !success) return this.message = message

                this.successMessage = 'Cadastro realizado com sucesso!';

                setTimeout(() => this.created = true, 2000)

                
            } catch (error) {

                this.message = 'Houve um erro ao cadastrar!';

            } finally {

                this.loading = false;

            }

        },

        submit: async function (e) {

            try {
                
                e.preventDefault();
    
                if (this.passwordError) {
                    this.alertText = "As senhas devem ser iguais.";
                    this.hasError = true;
                    this.isSuccess = false;
                    return;
                }
    
                let formData = new FormData();
    
                formData.append("nome", this.name);
                formData.append("email", this.email);
                formData.append("telefone",this.phone);
                formData.append("password",this.password);
    
                const response = await fetch(`${window.url}cadastro`, { method: "POST", body: formData })
    
                const { data, success, message } = await response.json()

                if(!data || !success) {
                    
                    this.alertText = message || "Erro no cadastro. Tente novamente.";
                    this.isSuccess = false;
                    this.hasError = true;
                    return
                }
    
                this.name = "";
                this.email = "";
                this.phone = "";
                this.password = "";
                this.confirmPassword = "";
                this.alertText = "Usuário cadastrado com sucesso";
                this.hasError = false;
                this.isSuccess = true;

            } catch (error) {

                this.alertText = error.message || "Ocorreu um erro inesperado.";
                this.isSuccess = false;
                this.hasError = true;
                return
                
            }
        },
        mounted() {
            this.alertText = "";
            this.hasError = false;
            this.isSuccess = false;
        },
    },
};
</script>

<style>   

    @media (max-width: 600px) {
        .flex-direction {
            flex-direction: column !important;
        }
    }



















    .modal-mask {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
        color: #464E5F !important;
        z-index: 9998 !important;
        overflow: scroll;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        background-color: #fff !important;
        width: 488px !important;
        height: 100% !important;
        margin: 0px auto;
        /*padding: 20px 30px;*/
        /*background-color: #fff;*/
        border-radius: 24px !important;
        /*box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);*/
        transition: all 0.3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-default-button {
        float: right;
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .close-button {
        float: right;
        background-color: #fff;
        background-repeat:no-repeat;
        border: none;
        cursor:pointer;
        overflow: hidden;
        outline:none;
        font-weight: 600;
        color: black !important;
    }





</style>