<template>
   
    <section class="client-section pt-5 pb-5 gray-light-bg">
        <div class="container">
            
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="section-heading text-center mb-5">
                        <h2>
                            Todos os lubrificantes e filtros do mercado em um só lugar
                        </h2>
                    </div>
                </div>
            </div>
           
            <div class="row align-items-center pb-3">
                <div class="col-md-12">
                    <carousel
                        :items="1"
                        :nav="false"
                        :autoplay="true"
                        :dots="false"
                        :loop="true"
                        :margin="15"
                        slideTransition="linear"
                        :autoplayTimeout="4000"
                        :autoplaySpeed="false"
                        :smartSpeed="6000"
                        :responsive="{
                            0: { items: 2 },
                            500: { items: 3 },
                            600: { items: 4 },
                            800: { items: 5 },
                            1200: { items: 6 },
                        }"
                        class="owl-theme clients-carousel dot-indicator owl-loaded owl-drag"
                    >
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/petronas-site.jpg"
                                alt="Petronas"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/repsol-site.jpg"
                                alt="Repsol"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/shell1-site.jpg"
                                alt="Shell"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/texaco-site.jpg"
                                alt="Texaco"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/total-site.jpg"
                                alt="Total"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/ypf-site.jpg"
                                alt="YPF"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/castrol-site.jpg"
                                alt="Castrol"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/Ipiranga-site.jpg"
                                alt="Ipiranga"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/lubrax-site.jpg"
                                alt="Lubrax"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/maxon-site.jpg"
                                alt="Maxon"
                                class="client-img client-img-dist"
                            />
                        </div>
                    </carousel>
                </div>
            </div>

            <div class="row align-items-center pt-4">
                <div class="col-md-12">
                    <carousel
                        :items="1"
                        :nav="false"
                        :autoplay="true"
                        :dots="false"
                        :loop="true"
                        :margin="15"
                        slideTransition="linear"
                        :autoplayTimeout="4000"
                        :autoplaySpeed="false"
                        :smartSpeed="6000"
                        :responsive="{
                            0: { items: 2 },
                            500: { items: 3 },
                            600: { items: 4 },
                            800: { items: 5 },
                            1200: { items: 6 },
                        }"
                        class="owl-theme clients-carousel dot-indicator owl-loaded owl-drag"
                    >
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/Vox-site.jpg"
                                alt="Vox"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/Wega-site.jpg"
                                alt="Wega"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/Fram-site.jpg"
                                alt="Fram"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/Hengst-site.jpg"
                                alt="Hengst"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/Japanparts-site.jpg"
                                alt="Japan Parts"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/Mann-site.jpg"
                                alt="Mann"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/motorcraft-site.jpg"
                                alt="MotorCraft"
                                class="client-img client-img-dist"
                            />
                        </div>
                        <div class="item single-client single-client-dist">
                            <img
                                src="img/Tecfil-site.jpg"
                                alt="Tecfil"
                                class="client-img client-img-dist"
                            />
                        </div>
                    </carousel>
                </div>
            </div>
           
        </div>
    </section>
    

    
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
    name: "Customer",
    components: { carousel },
    props: {
        isGray: {
            type: Boolean,
            default: false,
        },
        paddingTop: {
            type: Boolean,
            default: true,
        },
        paddingBottom: {
            type: Boolean,
            default: true,
        },
    },
};
</script>



<style>
.single-client-dist{
    height: 100% !important;
    width: 100% !important;
}

.client-img-dist{
    height: 100% !important;
    width: 100% !important;
    border-radius: 7px !important;
}
</style>