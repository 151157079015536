var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"promo-section pt-100 background-shape-img",attrs:{"id":"promo"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row equal"},[_c('div',{staticClass:"col-md-4 col-lg-4"},[_c('div',{staticClass:"single-promo single-promo-hover single-promo-1 rounded text-center white-bg pt-5 pr-5 pl-5 h-75",staticStyle:{"bottom":"-60px","position":"relative"}},[_c('div',{staticStyle:{"position":"relative","top":"-120px"}},[_vm._m(1),_c('h5',{staticStyle:{"margin-bottom":"2rem"}},[_vm._v("+27 mil veículos")]),_c('p',[_vm._v(" Manual técnico de todos os veículos registrados na tabela FIPE. ")]),_c('carousel',{staticClass:"owl-theme clients-carousel dot-indicator owl-loaded owl-drag pr-5 pl-5",attrs:{"items":1,"nav":false,"autoplay":true,"dots":false,"loop":true,"margin":10,"slideTransition":"linear","autoplayTimeout":4000,"autoplaySpeed":false,"smartSpeed":4000,"responsive":{
                                0: { items: 3 },
                                500: { items: 3 },
                                600: { items: 3 },
                                800: { items: 3 },
                                1200: { items: 3 },
                            }}},[_c('div',{staticClass:"item single-client-marcas pt-1 pb-1"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Fiat.png","alt":"Fiat"}})]),_c('div',{staticClass:"item single-client-marcas pt-1 pb-1"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Chevrolet.png","alt":"Chevrolet"}})]),_c('div',{staticClass:"item single-client-marcas pt-1 pb-1"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Vw.png","alt":"VW"}})]),_c('div',{staticClass:"item single-client-marcas pt-1 pb-1"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Ford.png","alt":"Ford"}})]),_c('div',{staticClass:"item single-client-marcas pt-1 pb-1"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Hyundai.png","alt":"Hyundai"}})]),_c('div',{staticClass:"item single-client-marcas pt-1 pb-1"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Toyota.png","alt":"Toyota"}})]),_c('div',{staticClass:"item single-client-marcas pt-1 pb-1"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Honda.png","alt":"Honda"}})]),_c('div',{staticClass:"item single-client-marcas pt-1 pb-1"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Peugeot.png","alt":"Peugeot"}})]),_c('div',{staticClass:"item single-client-marcas pt-1 pb-1"},[_c('img',{staticClass:"client-img",attrs:{"src":"img/Renault.png","alt":"Renault"}})])])],1)])]),_vm._m(2),_vm._m(3)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-8 col-md-9"},[_c('div',{staticClass:"section-heading text-center mb-5 d-flex flex-column",staticStyle:{"gap":"2rem"}},[_c('h2',[_vm._v(" Indicação de itens de manutenção técnica para cada veículo ")]),_c('p',{staticClass:"lead"},[_vm._v(" O que o iLub entrega na hora da troca de óleo? ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"circle-icon"},[_c('img',{staticStyle:{"width":"260px","height":"180px","position":"relative","margin-bottom":"0px !important"},attrs:{"src":"img/generic-sedan.png","alt":"sedan"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 col-lg-4"},[_c('div',{staticClass:"single-promo single-promo-hover single-promo-1 rounded text-center white-bg pt-5 pr-5 pl-5 h-75",staticStyle:{"bottom":"-60px","position":"relative"}},[_c('div',{staticStyle:{"position":"relative","top":"-120px"}},[_c('div',{staticClass:"circle-icon"},[_c('img',{staticStyle:{"width":"260px","height":"180px","position":"relative","margin-bottom":"0px !important"},attrs:{"src":"img/lubrificante-ilub-lateral.png","alt":"oil"}})]),_c('h5',{staticStyle:{"margin-bottom":"2rem"}},[_vm._v("Óleo do motor ideal")]),_c('p',[_vm._v(" Receba a indicação exata do óleo que atende às especificações do motor do veículo. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 col-lg-4"},[_c('div',{staticClass:"single-promo single-promo-hover single-promo-1 rounded text-center white-bg pt-5 pr-5 pl-5 h-75",staticStyle:{"bottom":"-60px","position":"relative"}},[_c('div',{staticStyle:{"position":"relative","top":"-120px"}},[_c('div',{staticClass:"circle-icon"},[_c('img',{staticStyle:{"width":"230px","height":"180px","position":"relative","margin-bottom":"0px !important"},attrs:{"src":"img/generic-filter.png","alt":"filter"}})]),_c('h5',{staticStyle:{"margin-bottom":"2rem"}},[_vm._v("Filtros compatíveis")]),_c('p',[_vm._v(" Identifique e realize e conversão rapidamente dos filtros corretos para cada modelo. ")])])])])
}]

export { render, staticRenderFns }