<template>
    <div class="overflow-hidden">
        <section
            id="pricing"
            class="package-section background-shape-img pb-5" style="padding-top: 8rem"
        >
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="section-heading text-center mb-5 d-flex flex-column" style="gap: 2rem">
                            <h2>
                                Contrate o iLub 
                            </h2>
                            <p class="lead">
                                Para continuar com uma experiência completa escolha um plano que melhor irá te atender.
                            </p>
                        </div>
                    </div>
                </div>
                <!-- <div class="row justify-content-center">
                    
                    <div v-for="(item, i) in array" :key="i" class="col-lg-4 col-md">
                        <div style="z-index: 1 !important;" class="card text-center single-pricing-pack">
                            <div class="card-header" style="border-radius: 2em 2em 0 0;background-color: #000033;border:0px !important;">
                                <div><h5 style="margin:0 !important;color:#FFF;">{{ item.nome }}</h5></div>
                            </div>
                            <div class="card-body" style="color:#707070 !important;">
                                <div class="h1 text-center mb-0">
                                    R$ <span class="price font-weight-bolder"
                                        >{{ calculaPrecoPorMes(item.validade, item.valor) }}</span
                                    >
                                </div>
                                <p>por cnpj/mês</p>
                              
                                <a
                                    :href="urlApp"
                                    class="btn outline-btn mt-4 mb-3"
                                    target="_blank"
                                    >Quero esse</a
                                >
                                <ul
                                    class="list-unstyled text-sm mb-3 pricing-feature-list"
                                >
                                    <li :class="i < 1 ? 'invisible' : ''">Valor total: R$ {{ item.valor.replace(",","").replace(".",",") }}</li>
                                    <li >{{ item.descricao }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
        
                </div> -->
                <!-- <div class="pt-2 mt-5 text-center">
                    <p class="mb-2">
                        Precisa de um serviço customizado?
                        <a style="cursor:pointer !important;color: #FF2B57 !important;" to="#contact" v-scroll-to="'#contact'"> Entre em contato conosco!</a>
                    </p>
                </div> -->
                <!--pricing faq start-->
                <div class="row mt-5">
                    <div class="col-lg-12">
                        <accordian id="accordion-1" :contents="contents" />
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-lg-12">
                        <accordian id="accordion-2" :contents="contents1" />
                    </div>
                </div> -->
                <!--pricing faq end-->
            </div>
        </section>
    </div>
</template>

<script>
import Accordian from "../../components/Accordian";
export default {
    name: "Pricing",
    isGray: {
        type: Boolean,
        default: false,
    },
    isPrimary: {
        type: Boolean,
        default: false,
    },
    components: {
        Accordian,
    },
    data: function () {
        return {
            urlApp: window.urlApp+'login?redirect=plano',
            array: [],
            calculaPrecoPorMes(dias, valor) {

                const meses = dias / 30

                const resultado = Number(valor) / meses

                return String(resultado.toFixed(2)).replace(".", ",")

            },
            contents: [
                {
                    title: "Como faço para criar uma conta no iLub?",
                    description:
                        "Para criar sua conta, basta clicar no botão \"Inscreva-se\" no site ou baixar o app. Preencha o formulário com seu nome, documento, telefone, e-mail e crie uma senha. Acesso liberado imediatamente! ",
                    active: false,
                    icon: "img/user-alt.svg",
                },

                {
                    title: "Como faço para consultar os dados do manual técnico de um veículo no app?",
                    description:
                        "No app, você pode consultar o manual técnico do veículo de três formas: digitando a placa, ou o modelo do veículo, ou selecionando a montadora, ano e modelo. Todas as opções mostram as indicações corretas de óleo e filtros.",
                    active: false,
                    icon: "img/search.svg",
                },
                {
                    title: "Como alterar as marcas que aparecem na recomendação de produtos?",
                    description:
                        "Abra o menu no canto superior esquerdo, vá até \"Configurações\" e selecione os produtos com as marcas configuradas. Escolha a marca que deseja que apareça primeiro nas recomendações.",
                    active: false,
                    icon: "img/redo-alt-duotone.svg",
                },
                {
                    title: "Como gerar a etiqueta de troca ao final da revisão?",
                    description:
                        "Após registrar a troca na tela de produtos indicados, clique em \"Registrar Troca\". Preencha os dados do atendimento e, ao final, clique em \"Gerar Etiqueta\" para visualizar e imprimir a etiqueta de troca de óleo",
                    active: false,
                    icon: "img/print.svg",
                },
                {
                    title: "Posso compartilhar assinatura?",
                    description:
                        "Não, a assinatura do iLub é exclusiva para 1 usuário. Caso precise de algum atendimento especial, entre em contato conosco.",
                    active: false,
                    icon: "img/unlock-alt-close-duotone.svg",
                },
                {
                    title: "Posso cancelar a assinatura do app?",
                    description:
                        "Se a renovação não ocorrer, a assinatura será cancelada automaticamente, de forma simples e sem burocracia.",
                    active: false,
                    icon: "img/info.svg",
                },
            ],
        };
    },
    methods: {

        async buscarPrecos() {

            let response = await fetch(`${window.url}plano`)

            const { data } = await response.json()

            this.array = data

        },
    },
    mounted() {
        this.buscarPrecos();
    }
   
};
</script>

<style>
</style>