<template>
    <section id="comunication" class="about-us pt-5 mt-3 mb-3">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-9">
                    <div class="section-heading text-center mb-5">
                        <h2>
                            Melhore seu Fluxo de Trabalho e otimize o processo de atendimento
                        </h2>
                    </div>
                </div>
            </div>
            <div class="row align-items-center justify-content-between">
                <div class="col-md-12">
                    <div class="about-content-left section-heading">
                        <div class="single-feature d-flex flex-column" style="gap: 1rem">
                            <div class="row" style="align-items: center;">
                                <div class="about-content-left col-2">
                                    <img
                                        src="img/pesquisa-car.png"
                                        alt="about us"
                                        class="img-fluid pl-5"
                                        style="width: 85%;"
                                    />
                                </div>
                                <p class="lead col-10">
                                    <b>Localize o veículo rapidamente</b> pela placa e visualize os itens corretos.
                                </p>
                            </div>
                            <div class="row" style="align-items: center;">
                                <div class="about-content-left col-2">
                                    <img
                                        src="img/pesquisa-user.png"
                                        alt="about us"
                                        class="img-fluid pl-5"
                                        style="width: 85%;"

                                    />
                                </div>
                                <p class="lead col-10">
                                    Insira dados do cliente e do veículo durante a troca para um <b>atendimento mais organizado.</b>
                                </p>
                            </div>
                            <div class="row" style="align-items: center;">
                                <div class="about-content-left col-2">
                                    <img
                                        src="img/pesquisa-print.png"
                                        alt="about us"
                                        class="img-fluid pl-5"
                                        style="width: 85%;"
                                    />
                                </div>
                                <p class="lead col-10">
                                    <b>Gere e imprima etiquetas</b> completas com todas as informações da troca de óleo.
                                </p>
                            </div>
                            <div class="row" style="align-items: center;">
                                <div class="about-content-left col-2">
                                    <img
                                        src="img/pesquisa-clock.png"
                                        alt="about us"
                                        class="img-fluid pl-5"
                                        style="width: 85%;"
                                    />
                                </div>
                                <p class="lead col-10">
                                    <b>Salve os registros</b> para monitorar e analisar o <b>histórico de atendimentos</b> com facilidade.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "About",
};
</script>

<style>
</style>